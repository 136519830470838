import App from './App.vue'
import './assets/style/tailwind.sass'
import config from './config'
import clickOutside from './directives/clickOutside'
import prototypesPlugin from './plugins/prototypes'
import router from './router'
import Keycloak from 'keycloak-js'
import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { init as initProject } from '@/composables/useProject'
import * as Sentry from '@sentry/vue'

const initApp = async () => {
  const { default: VueDatePicker } = await import('@vuepic/vue-datepicker')
  await import('@vuepic/vue-datepicker/dist/main.css')
  const { DynamicScroller, DynamicScrollerItem, RecycleScroller } = await import(
    'vue-virtual-scroller'
  )
  await import('vue-virtual-scroller/dist/vue-virtual-scroller.css')

  const app = createApp(App)
  connectToSentry(app)
  app
    .use(router)
    .use(prototypesPlugin)
    .component('DynamicScroller', DynamicScroller)
    .component('DynamicScrollerItem', DynamicScrollerItem)
    .component('RecycleScroller', RecycleScroller)
    .component('VueDatePicker', VueDatePicker)
    .directive('click-outside', clickOutside)
    .mount('#app')
  const { addAxiosErrorHandler } = await import('./composables/useRequestErrorHandler')
  addAxiosErrorHandler(config.urls.AXIOS_API)
  return app
}

let keycloak = new Keycloak(`${config.urls.URL_API}/api/auth/config/`)
console.log({ keycloak })
keycloak
  .init({ onLoad: 'login-required' })
  .then(async auth => {
    if (!auth) {
      window.location.reload()
      return
    }

    const { setKeycloak, updateTokenRef } = await import('@/composables/useAuth')
    setKeycloak(keycloak)
    updateTokenRef()
    initApp()
    initProject()

    const refresh = () =>
      keycloak
        .updateToken(70)
        .then(refreshed => (refreshed ? updateTokenRef() : undefined))
        .catch(() => console.error('Failed to refresh token'))

    setInterval(() => refresh(), 6000)
  })
  .catch(async () => {
    // const { createRouter, createWebHistory } = await import('vue-router')

    const router = createRouter({
      routes: [
        { path: '/', component: () => import('@/pages/error-unavailable') },
        { path: '/:pathMatch(.*)*', component: () => import('@/pages/error-unavailable') },
      ],
      history: createWebHistory(),
    })
    createApp(App).use(router).mount('#app')
  })

const connectToSentry = app => {
  const isLocalhost = import.meta.env.MODE === 'development'
  const nonErrorReplayLevel = isLocalhost ? 1.0 : 0.1
  // no need to capture exception here: if Sentry not be accessible it will be ignored
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false }),
    ],
    // debug: isLocalhost,  // Uncomment to debug Sentry. Commented out because it writes a lot to the console
    environment: process.env.NODE_ENV,
    release: config.VERSIONS.value.frontend.version,
    trackComponents: true,
    tracePropagationTargets: ['localhost', config.urls.URL_API],
    tracesSampleRate: nonErrorReplayLevel,
    replaysSessionSampleRate: nonErrorReplayLevel,
    replaysOnErrorSampleRate: 1.0,
  })
}
